<template>
    <!-- PAGE HEADER -->
	<div class="page_header"></div>
	<!-- // PAGE HEADER -->

    <div class="container single-post padding-bottom-30">
		<div class="row">

			<div class="clearfix"></div>
			<div class="col-md-8 col-sm-7 padding-bottom-30">

                <DataView :value="dataView.data" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
                    :rowsPerPageOptions="dataView.rows_option"
                    :layout="dataView.layout" 
                    :paginator="dataView.paginator" 
                    :rows="dataView.params.rows" 
                    :totalRecords="dataView.totalRecords" 
                    :lazy="true" 
                    @page="loadData($event)">
                    <template #list="slotProps" >
						<Timeline :value="[slotProps.data]" class="mb-5">
							<template #opposite="slotProps2">
								<p class="p-text-secondary">{{ $filter_date(slotProps2.item.tanggal) }} | {{ slotProps2.item.waktu_mulai.substr(0,5) }} - {{ slotProps2.item.waktu_selesai.substr(0,5) }} WIB </p>
							</template>
							<template #marker>
								<span class="custom-marker shadow-2" :style="{backgroundColor: '#607D8B'}">
									<i class="pi pi-calendar-times"></i>
								</span>
							</template>
							<template #content="slotProps3">
								<Card>
									<template #title>
										<h4><router-link :to="`/k/${slotProps3.item.id}/${slotProps3.item.slug}`">{{slotProps3.item.nama}}</router-link></h4>
									</template>
									<template #subtitle>
										
									</template>
									<template #content>
										<p>{{ $filter_text_limit(slotProps3.item.deskripsi, 30) + '...' }}</p>
										<Button class="p-button-outlined" @click="$router.push(`/k/${slotProps3.item.id}/${slotProps3.item.slug}`)">Selelngkapnya</Button>
									</template>
								</Card>
							</template>
						</Timeline>
                    </template>

                    <template #grid="slotProps">
                        {{ slotProps }}
                    </template>
                </DataView>

			</div>
	
			<!-- SIDEBAR -->
			<Sidebar :sidebar="sidebar" />
			<!-- // SIDEBAR -->

		</div>
	</div>
	<!-- // CONTENT -->
</template>

<script>
import Sidebar from '@/landing/_Sidebar.vue'

export default {
    components: {
		Sidebar,
	},
	props: ['settings', 'sidebar', 'id', 'slug'],
	data() {
		return {
			title: 'Kegiatan',
			api: '/api/landing',
			dataView: {
				data: [],
				paginator: true,
				layout: 'list',
				totalRecords: 0,
				rows_option: [5, 10, 50],
				selectedData: [],
				params: {
					rows: 5, //per_page
					page: 0,
					// sortField: null,
					// sortOrder: null,
				},
			},
			skeleton: {
				kegiatan: true
			},
			events1: [
                {status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg'},
                // {status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7'},
                // {status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800'},
                // {status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B'}
            ],
		}
	},
	computed: {
		
 	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			(event) ? this.dataView.params = event : null;
			this.$httpAuth.get(this.api + '/kegiatan-list', {
				params: {
					rows: this.dataView.params.rows,
					page: this.dataView.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataView.data = response.data.data;
				this.dataView.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataView.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
	},
	created(){
		this.loadData();
	},
	mounted() {
		
	},
}
</script>

<style scoped>
.img-responsive {
    width: 100%;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
	border: unset !important;
}
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>